<template>
  <div class="d-flex flex-column">
    <div class="div-home1">
      <img class="w-100" :src="isMobile ? assets.home_bg1_mobile : assets.home_bg1" />
      <div class="div-home1-content center">
        <span class="font-bold home1-content-title">EXPLORE THE WORLD</span>
        <span class="font-bold home1-content-title">WITH EVENTBOX</span>
        <div class="mt-10 d-flex justify-content-between">
          <a :href="APP_STORE_URL" target="_blank" v-if="platform === 'iOS' || platform === 'Desktop'">
            <img class="mx-5 store-image" :src="assets.app_store" />
          </a>
          <a :href="GOOGLE_PLAY_URL" target="_blank" v-if="platform === 'Android' || platform === 'Desktop'">
            <img class="store-image" :src="assets.google_play" />
          </a>
        </div>
        <div class="w-100">
          <div class="div-search m-10 d-flex justify-content-between vertical-center">
            <i :class="`${isMobile ? 'ml-3 font-15' : 'ml-5 font-20'} fa fa-search color-icon`" />
            <input :class="`w-50 ${isMobile ? 'px-2' : 'px-5'}`" v-model="keyword" :placeholder="$t('event_name')" @keydown.enter.exact.prevent="refreshData" />
            <span>|</span>
            <img class="ml-2" :src="assets.address" :width="isMobile ? 15 : 20" />
            <input :class="`w-50 ${isMobile ? 'px-2' : 'px-5'}`" v-model="address" :placeholder="$t('address')" @keydown.enter.exact.prevent="refreshData" />
            <v-btn class="m-1 button-round-20" @click="refreshData()">
              <span class="font-12">{{ $t(isMobile ? 'find' : 'find_events') }}</span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="div-home2">
      <div class="d-flex justify-content-between vertical-center">
        <span :class="`${isMobile ? 'font-20' : 'font-25'} font-bold color-white`">{{ $t('top_events') }}</span>
        <v-btn text @click="$router.push('/live_events')" v-if="!isMobile">
          <span :class="`${isMobile ? 'font-12' : 'font-20'} color-white`">{{ $t('discover_more_events') }}<i :class="`${isMobile ? 'font-12' : 'font-20'} ml-2 color-white fa fa-angle-right`" /></span>
        </v-btn>
      </div>
      <div class="row mt-5" v-if="items.length > 0">
        <div class="col-lg-3 col-md-6 col-sm-12 my-2" v-for="(item, index) in items" :key="(item, index)">
          <v-card class="mx-auto box-white p-0 d-flex flex-column">
            <v-img class="rounded event-image pointer" :src="hostname === 'localhost' && FOR_MA ? index % 2 === 0 ? assets.testImage1 : assets.testImage2 : item.photoUrl" @click="gotoEventDetail(item)" />
            <div class="m-3 h-180px d-flex flex-column">
              <span :class="`ellipsis font-bold ${isMobile ? 'font-15' : 'font-18'}`" :title="item.name">{{ item.name }}</span>
              <div class="mt-3 vertical-center">
                <i :class="`fa fa-calendar-alt color-icon ${isMobile ? 'font-12' : 'font-15'}`"></i>
                <span :class="`ml-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ getDateStringFromTimestamp(item.startAt, 'ddd, DD MMM • hh:mm A') }}</span>
              </div>
              <div class="mt-2 vertical-center">
                <i :class="`flaticon2-pin-1 color-icon ${isMobile ? 'font-12' : 'font-15'}`"></i>
                <span :class="`ml-2 ${isMobile ? 'font-12' : 'font-15'}`">{{ item.hideAddress ? $t('private') : getCountryCityFromAddress(item.address) }}</span>
              </div>
              <span :class="`mt-2 ellipsis-2 ${isMobile ? 'font-12' : 'font-15'}`" :title="item.description">{{ item.description }}</span>
              <div class="mt-5 d-flex justify-content-between vertical-center">
                <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('from') }}&nbsp;{{ getPriceFormat(item.ticketMinPrice) }}</span>
                <v-btn class="button-pink-light-small" @click="gotoEventDetail(item)">
                  <span :class="`${isMobile ? 'font-10' : 'font-12'} color-pink`">{{ $t('ticket') }}<i :class="`${isMobile ? 'font-10' : 'font-12'} ml-2 color-pink fa fa-angle-right`" /></span>
                </v-btn>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="my-30 d-flex justify-content-center" v-else>
        <span class="my-30 py-40 font-15 color-white">{{ $t('no_data_found') }}</span>
      </div>
      <div class="d-flex justify-content-center" v-if="isMobile">
        <v-btn class="m-5 w-100 button-white-border" text @click="$router.push('/live_events')">
          <span :class="`${isMobile ? 'font-12' : 'font-20'} color-white`">{{ $t('discover_more_events') }}<i :class="`${isMobile ? 'font-12' : 'font-20'} ml-2 color-white fa fa-angle-right`" /></span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/home.scss";
</style>

<script>
import { getPlatform, getDateStringFromTimestamp, getCountryCityFromAddress, getEventParam, getPriceFormat } from '../../functions';

import home_bg1 from '@/assets/images/home_bg1.jpg';
import home_bg1_mobile from '@/assets/images/home_bg1_mobile.jpg';
import address from '@/assets/images/address.png';
import testImage1 from '@/assets/images/test_image1.jpg';
import testImage2 from '@/assets/images/test_image2.jpg';
import google_play from '@/assets/images/google_play.png';
import app_store from '@/assets/images/app_store.png';

export default {
  name: 'Home',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    liveEventList() {
      return this.$store.state.liveEventList || [];
    }
  },
  watch: {
    liveEventList() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        home_bg1,
        home_bg1_mobile,
        address,
        testImage1,
        testImage2,
        google_play,
        app_store
      },
      hostname: window.location.hostname,
      platform: getPlatform(),
      keyword: '',
      address: '',
      items: []
    };
  },
  mounted() {
    const redirectPath = localStorage.getItem('eventboxRedirectPath');
    if (redirectPath) {
      localStorage.setItem('eventboxRedirectPath', '');
      if (redirectPath === '/payment/checkout') {
        if (this.$store.state.myId) {
          this.$router.push(redirectPath);
        }
      } else {
        this.$router.push(redirectPath);
      }
    }
    this.refreshData();
  },
  methods: {
    getDateStringFromTimestamp,
    getCountryCityFromAddress,
    getPriceFormat,
    refreshData() {
      const keyword = this.keyword.toLowerCase();
      const address = this.address.toLowerCase();
      if (keyword || address) {
        this.items = this.liveEventList.filter(element => (!keyword || element.name.toLowerCase().includes(keyword)) && (!address || element.address.toLowerCase().includes(address)));
      } else {
        this.items = this.liveEventList.slice(0, Math.min(this.liveEventList.length, this.isMobile ? 4 : 8));
      }
    },
    gotoEventDetail(eventInfo) {
      this.$router.push(`/e/${getEventParam(eventInfo)}`);
    }
  }
};
</script>
